
import UploadHeader from './UploadHeader.vue';
import UploadBlock from './UploadBlock.vue';
import { Component, Vue, ModelSync } from 'vue-property-decorator';
import { FileAnalysisModule } from '@/store/modules/file_analysis';

@Component({
  name: 'IntelligentIdentificationDialog',
  components: {
    UploadBlock,
    UploadHeader
  }
})
export default class extends Vue {
  @ModelSync('dialogVisible', 'changeVisible', { type: Boolean }) public value!: boolean;

  public isNotUpload = true;

  private headerInfo: any = {
    orange: {
      imgUrl: require('../../assets/image/common/orange-idcard.jpg'),
      name: 'green-passport',
      headerText: 'stuManagement.uploadPassportNational'
    },
    green: {
      imgUrl: require('../../assets/image/common/green-passport.jpg'),
      name: 'green-passport',
      headerText: 'stuManagement.uploadPassport'
    },
    blue: {
      imgUrl: require('../../assets/image/common/blue-background.jpg'),
      name: 'blue-background',
      headerText: 'stuManagement.uploadBackground'
    }
  }
  private contents: any = {
    orange: {
      hasUploaded: false,
      status: [
        {
          title: 'stuManagement.stuName',
          key: 'nameZh',
          exist: false,
        },
        {
          title: 'stuManagement.stuNameEn',
          key: 'firstName',
          exist: false,
        },
        {
          title: 'stuManagement.stuGender',
          key: 'gender',
          exist: false,
        },
        {
          title: 'stuManagement.stuBirthPlace',
          key: 'cityBirth',
          exist: false,
        },
        {
          title: 'stuManagement.stuPassport',
          key: 'passportNumber',
          exist: false,
        },
      ],
    },
    green: {
      hasUploaded: false,
      status: [
        {
          title: 'stuManagement.stuName',
          key: 'nameZh',
          exist: false,
        },
        {
          title: 'stuManagement.stuNameEn',
          key: 'firstName',
          exist: false,
        },
        {
          title: 'stuManagement.stuGender',
          key: 'gender',
          exist: false,
        },
        {
          title: 'stuManagement.stuBirthPlace',
          key: 'cityBirth',
          exist: false,
        },
        {
          title: 'stuManagement.stuPassport',
          key: 'passportNumber',
          exist: false,
        },
      ],
    },
    blue: {
      hasUploaded: false,
      status: [
        {
          title: 'stuManagement.stuEduExp',
          key: 'eduBack',
          exist: false,
        },
        {
          title: 'stuManagement.stuWorkExp',
          key: 'workExp',
          exist: false,
        },
      ]
    }
  }

  public handleSetAnalysisStatus(obj: any) {
    const personInfo = obj.personInfo;
    switch (obj.type) {
      case 'issueFileResolverPassportNational':
        const idCardStatus = this.contents.orange.status.map((item: any) => {
          if (personInfo[item.key] !== undefined) {
            item.exist = true;
          }
          return item;
        })
        this.contents.orange.status = idCardStatus;
        this.contents.orange.hasUploaded = true;
        break;
      case 'issueFileResolverPassportCn':
        const passportStatus = this.contents.green.status.map((item: any) => {
          if (personInfo[item.key] !== undefined) {
            item.exist = true;
          }
          return item;
        })
        this.contents.green.status = passportStatus;
        this.contents.green.hasUploaded = true;
        break;
      // case 'issueFileResolverEduBackground':
      //   const eduBack = obj.eduBack;
      //   const workExp = obj.workExp;
      //   const backInfoStatus = this.contents.blue.status.map((item: any) => {
      //     if (item.key === 'eduBack') {
      //       item.exist = eduBack && eduBack.length > 0;
      //     } else if (item.key === 'workExp') {
      //       item.exist = workExp && workExp.length > 0;
      //     }
      //     return item;
      //   })
      //   this.contents.blue.status = backInfoStatus;
      //   this.contents.blue.hasUploaded = true;
    }
  }

  public handleContinueTofill() {
    this.close();
    this.$emit('link-to-register');
  }

  public close() {
    this.clearStatus();
    this.$emit('changeVisible', false);
  }

  public clearStatus() {
    const contents = this.contents;
    ['orange', 'green', 'blue'].map((key) => {
      contents[key].hasUploaded = false;
      const status = contents[key].status;
      contents[key].status = status.map((item: any) => {
        item.exist = false;
        return item;
      })
    })
  }
}
