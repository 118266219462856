
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'submitBtn'
})
export default class extends Vue {
  @Prop({ type: String, default: '' }) public type!: string;
  @Prop({ type: String, default: '' }) public name!: string;
  @Prop({ type: String, default: ''}) public width!: string;
  @Prop({ type: Boolean, default: false }) public loading!: boolean;
  @Prop({ type: Boolean, default: false }) public saveDisabled!: boolean;
  @Prop({ type: Boolean, default: false}) public isCommonBtn!: boolean;
  @Prop({ type: Boolean, default: false }) public isDefault!: boolean;

  public handleBtnClick() {
    this.$emit('handle-btn-click');
  }
}
