
import { Component, Ref, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { UserModule } from '@/store/modules/user';
import { FileAnalysisModule } from '@/store/modules/file_analysis';

@Component({
  name: 'studentApplicationForm'
})
export default class extends Vue {
  @Ref('allFormComp') public allFormComp!: any;

  get studentId() {
    if (isNaN(Number(this.$route.params.stuid))) {
      return '';
    }
    return Number(this.$route.params.stuid);
  }

  get companyId() {
    return UserModule.companyId;
  }

  get getLan() {
    return this.$i18n.locale;
  }

  get agentId() {
    if (isNaN(Number(UserModule.fkAgentId))) {
      return ''
    }
    return Number(UserModule.fkAgentId);
  }

  get sidebar() {
    return AppModule.sidebar;
  }

  get token() {
    return UserModule.getToken;
  }

  get publicFilesUrl() {
    return UserModule.publicFilesUrl;
  }

  get privateFilesUrl() {
    return UserModule.privateFilesUrl;
  }

  get bmsLogin() {
    return UserModule.bmsLogin;
  }
  get bmsLoginId(){
    return UserModule.bmsLoginId
  }
  get issueInnerAccount() {
    return UserModule.issueInnerAccount;
  }

  get eduBackInfo() {
    const eduBack = FileAnalysisModule.eduBack;
    if (eduBack && eduBack.length) {
      return eduBack;
    }
    return undefined;
  }

  get workExpInfo() {
    const workExp = FileAnalysisModule.workExp;
    if (workExp && workExp.length) {
      return workExp;
    }
    return undefined;
  }

  public beforeDestroy() {
    FileAnalysisModule.SET_WORK_EXP([]);
    FileAnalysisModule.SET_EDU_BACK([]);
    FileAnalysisModule.SET_PERSON_INFO({});
  }
}
