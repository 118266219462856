
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class extends Vue {
    @Prop({ default: '' }) private imgUrl!: string;
    @Prop({ default: '' }) private name!: string;
    @Prop({ default: '' }) private text!: string;

    private fileTypes: string[] = [
      'image/jpeg', 'image/png', 'application/pdf', 'image/bmp', 'image/gif', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'
    ];

    public beforeUpload(file: any) {
      if (!this.fileTypes.includes(file.type)) {
        this.$message.error('不支持该文件的格式，请重新上传符合格式要求的文件！');
        return false;
      } else if (file.size / 1024 / 1024 > 5) {
        this.$message.error('文件大小不超过5M，请您重新上传符合要求的文件！');
        return false;
      }
      return true;
    }

    public async handleUploadFile(params: any) {
      const file = params.file;
      this.$emit('file-upload', file);
    }

    public handleUploadSuccess(uploadFile: any) {
      this.$emit('click-header')
    }

    public handleUploadError(error: Error) {
      //
    }

  }
