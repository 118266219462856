
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'tableOperaBtn'
})
export default class TableOperaBtn extends Vue {
  @Prop({ type: String, default: '' }) private title!: number;
  @Prop({ type: Number, default: 0 }) private total!: number;
  @Prop({ type: Number, default: 0 }) private totalApply!: number;
  @Prop({ type: Boolean, default: false }) private showTotalNumber!: false; // 是否显示总条数据
  @Prop({ type: Number, default: 0 }) private totalNumber!: number; // 显示总条数
}
