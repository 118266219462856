import request from '@/utils/request_common';

// 个人信息国家下拉框
export const getAppCountryApi = () =>
  request({
    url: '/issue/student/getAppCountry',
    method: 'get',
  });

// 国家
export const getAllAreaCountryListApi = () => request({
  url: '/appInstitution/areaCountry/getAllAreaCountryList',
  method: 'get',
})

// 省份
export const getAreaSateApi = (id: number) =>
  request({
    url: `/appInstitution/areaState/getAreaIssue/${id}`,
    method: 'post',
  });

// 城市
export const getAreaCityApi = (id: number) =>
  request({
    url: `/appInstitution/areaCity/getByFkAreaStateId/${id}`,
    method: 'get',
  });

// 区
export const getRegionOptionsApi = (id: number) =>
  request({
    url: `/appInstitution/areaCity/getDivisionByFkAreaCityId/${id}`,
    method: 'get',
  });

// 学生申请汇总
export const getStudentListApi = (data: any) =>
  request({
    url: '/issue/student/getSelectDataByAgent',
    method: 'post',
    data
  });

// 学生资料管理
export const getStuApplyListApi = (data: any) =>
  request({
    url: '/issue/student/getStuApplyList',
    method: 'post',
    data
  });

// 学生申请汇总表格导出
export const exportEventExcelApi = (data: any) =>
  request({
    url: '/issue/student/exportEasyExcel',
    method: 'post',
    responseType: 'blob',
    data
  });

// 学生资料管理表格导出
export const exportStudentExcelApi = (data: any) =>
  request({
    url: '/issue/student/exportStudentExcel',
    method: 'post',
    responseType: 'blob',
    data
  });

// 电话区号
export const getPhoneRegionsApi = () =>
  request({
    url: '/issue/studentCountry/getPhoneRegion',
    method: 'get',
  });

// 民族血统
export const getStudentAncestryApi = () =>
  request({
    url: '/issue/student/getStudentAncestry',
    method: 'get',
  });

// 母语下拉
export const getModerLanguageApi = () =>
  request({
    url: '/issue/student/getModerLanguageData',
    method: 'get',
  });

// 学生注册
export const saveOrUpdateStudentInfoApi = (data: any) =>
  request({
    url: '/issue/student/saveOrUpdateStudentInFoData',
    method: 'post',
    data,
  });
// 验证学生唯一性
export const validateStudentInfoApi = (data: any) =>
  request({
    url: '/issue/student/validateStudentInfo',
    method: 'post',
    data,
  });
// 是否使用共享链接字段
export const getIsUseSharedPathApi = (bmsLoginId: any) =>
  request({
    url: '/issue/student/getIsUseSharedPath',
    method: 'get',
    params:{bmsLoginId},
});
// 下载打包全部文件
export const downLoadAllFileApi = (fkStudentId: any) =>
  request({
    url: '/appFile/issueDownloadList',
    method: 'post',
    params: { fkStudentId },
    responseType: 'blob',
  });

// 学生注册回显信息
export const getRegisterDataApi = (fkStudentId: number) =>
  request({
    url: `/issue/student/getRegisterData?fkStudentId=${fkStudentId}`,
    method: 'get',
  });

// 申请状态下拉
export const getApplicationStatusApi = () =>
  request({
    url: `/issue/student/getStepList`,
    method: 'get',
  });

// 申请失败原因下拉
export const getStepFailListApi = () =>
  request({
    url: `/issue/student/getStepFailList`,
    method: 'get',
  });

// 修改提交状态
export const updateStudentStatusApi = (status: number, fkStudentId: number | null) =>
  request({
    url: `/issue/student/updateStudentStatus?status=${status}&fkStudentId=${fkStudentId}`,
    method: 'get',
  });

// 获取首页数据
export const getSummaryDatasApi = (year: string) =>
  request({
    url: `/issue/summary/datas?year=${year}`,
    method: 'post',
  });

// 根据国家获取学校
export const getInstitutionListApi = (fkAreaCountryId: number) =>
  request({
    url: `/appInstitution/institution/getInstitutionList?fkAreaCountryId=${fkAreaCountryId}`,
    method: 'get',
  });

export const setBindFormApi = (receiptCode: string) =>
  request({
    url: `/issue/student/getSetIssueUserAgent?receiptCode=${receiptCode}`,
    method: 'get',
  });

export const enCvParseApi = (data: any) =>
  request({
    url: '/issue/student/getBackgroundInfoParse',
    method: 'post',
    data
  });
// 英国的下一级
export const getAreaRegionByCountryId = (id: number) => request({
  url: '/appInstitution/areaState/getAreaRegionByCountryId?fkCountryId=' + id,
  method: 'post'
})

export const getSucFlagApi = () =>
  request({
    url: `/issue/summary/getSucFlag`,
    method: 'get',
  });
// 当前登录账号及下属学生管理帐号列表
export const getStudentCreateUserIdListApi = () =>
  request({
    url: `/issue/student/getStudentCreateUserIdList`,
    method: 'get',
  });
// 批量转学生管理帐号
export const convertStuManagerUserApi = (data: any) =>
  request({
    url: `/issue/student/convertStuManagerUser`,
    method: 'post',
    data
  })
