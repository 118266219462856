
import { Component, Model, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'customSelect'
})
export default class extends Vue {
  @Model('change') public echoed!: any;

  @Prop() public options!: any;
  @Prop() public optionConfig!: any;
  @Prop({ default: true }) public filterable!: boolean;
  @Prop({ default: '' }) public placeholder!: string;

  get optionsLen() {
    return this.options.length;
  }
}
