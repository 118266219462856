import { render, staticRenderFns } from "./StudentInfoManagentment.vue?vue&type=template&id=9bbd9dba&scoped=true"
import script from "./StudentInfoManagentment.vue?vue&type=script&lang=ts"
export * from "./StudentInfoManagentment.vue?vue&type=script&lang=ts"
import style0 from "./StudentInfoManagentment.vue?vue&type=style&index=0&id=9bbd9dba&prod&lang=scss&scoped=true"
import style1 from "./StudentInfoManagentment.vue?vue&type=style&index=1&id=9bbd9dba&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bbd9dba",
  null
  
)

export default component.exports