
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'iconBtn'
})
export default class extends Vue {
  @Prop({ default: false }) private showEdit!: boolean;
  @Prop({ default: false }) private showDownload!: boolean;
  @Prop({ default: false }) private showPersonInfo!: boolean;
  @Prop({ default: false }) private showApplyFollow!: boolean;
  @Prop({ default: false }) private showAddApplyCourse!: boolean;
  @Prop({ default: false }) private showEditStatus!: boolean;
  @Prop({ default: false }) private showCheckStudentInfo!: boolean;

  @Prop({ type: String, default: ''}) private editText!: string;

  public handleEdit() {
    this.$emit('handle-edit');
  }
  public handlePersonInfo() {
    this.$emit('handle-person-info');
  }
  public handleDownload() {
    this.$emit('handle-download');
  }
  public handleApplyFollow() {
    this.$emit('handle-apply-follow');
  }
  public handleAddApplyCourse() {
    this.$emit('handle-add-apply-course');
  }
  public handleEditStatus() {
    this.$emit('handle-edit-status');
  }
  public handleCheckStudentInfo() {
    this.$emit('handle-check-student-info');
  }
}
