
import { Component,  Prop,  Vue } from 'vue-property-decorator'
import SaveBtnGroup from './SaveBtnGroup.vue';

@Component({
  name: 'commonDrawer',
  components: {
    SaveBtnGroup
  }
})
export default class extends Vue {
  @Prop() public drawer!: boolean;
  @Prop() public title!: string;

  public cancelDrawerForm() {
    this.$emit('cancel-drawer-form', this.$refs.submitBtn);
  }
  public submitDrawerForm() {
    this.$emit('submit-drawer-form', this.$refs.submitBtn);
  }
  public beforeClose() {
    this.$emit('close')
  }
}
