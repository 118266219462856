
import StudentMixin from './mixin/studentMixin';
import IconButton from '@/components/Common/IconBtn.vue';
import TableComp from '@/components/Common/TableComp.vue';
import SubmitBtn from '@/components/Common/SubmitBtn.vue';
import SearchGroup from '@/components/Common/SearchGroup.vue';
import CommonDrawer from '@/components/Common/CommonDrawer.vue';
import TableOperaBtn from '@/components/Common/TableOperaBtn.vue';
import CommonIconBtn from '@/components/Common/commonIconBtn.vue';
import SelectPageSearch from '@/components/Common/SelectPageSearch.vue';
import IntelligentIdentificationDialog from '@/components/IntelligentIdentification/IntelligentIdentificationInfoDialog.vue';
import batchTransferStudentDialog from './components/batchTransferStudentDialog.vue';

import { GlobalVar } from '@/utils/global-var';
import { debounce } from '@/utils/function_utils';
import { Component, Mixins, Ref } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import { getAllAreaCountryListApi, setBindFormApi } from '@/api/student-management';
import {
  getStepFailListApi,
  getStuApplyListApi,
  getInstitutionListApi,
  exportStudentExcelApi,
  updateStudentStatusApi,
  getStudentCreateUserIdListApi
} from '@/api/student-management';
import { IStudentInfoFilterTable, IStuResTableData } from '@/interface/studentManagement';

@Component({
  name: 'studentInfoManagement',
  components: {
    TableComp,
    SubmitBtn,
    IconButton,
    SearchGroup,
    CommonDrawer,
    TableOperaBtn,
    CommonIconBtn,
    SelectPageSearch,
    IntelligentIdentificationDialog,
    batchTransferStudentDialog
  },
})
export default class extends Mixins(StudentMixin) {
  @Ref('courseApplyStatusForm') public courseApplyStatusRef!: any;

  get showAppStatusPermission() {
    return UserModule.isShowAppStatusPermission;
  }

  get companyId() {
    return UserModule.companyId;
  }

  get isBmsLogin() {
    return UserModule.bmsLogin;
  }

  get agentId() {
    return UserModule.fkAgentId;
  }

  public reqFilterTable: IStudentInfoFilterTable = {
    keyWord: '',
    startTime: '',
    endTime: '',
    fkInstitutionName: '',
    fkAreaCountryId: null,
    fkInstitutionId: null,
    stepKey: ''
  }
  // 勾选列表
  public selectList: any=[];
  // 是否显示批量转学生
  public showTransferDialog: boolean=false;
  public stepStatusKey: string = '';
  public stepFailKey: string = '';
  public drawer = false;
  public dialogVisible = false;
  public currentStudentId: number | null = null;
  public stepFailOptions: any[] = [];

  public courseApplyStatus: any = {
    status: ''
  }

  public countryOptions: any[] = [];
  public institutionOptions: any[] = [];
  public resTableData: IStuResTableData[] = [];

  public mergePos: any = [];
  public mergeData: any = [];
  public mergeProp: any = ['id', 'fkAreaCountryId', 'fkInstitutionId'];


  public courseApplyStatusOptions = [
    { label: this.$t('stuManagement.courseApplyStatus0'), value: 0 },
    { label: this.$t('stuManagement.courseApplyStatus1'), value: 1 },
    { label: this.$t('stuManagement.courseApplyStatus2'), value: 2 },
    { label: this.$t('stuManagement.courseApplyStatus3'), value: 3 },
  ];

  // 利用防抖避免连续多次点击发送多次请求
  public getTables = debounce(this.getTableDatas, 500);

  public activated() {
    const route = this.$route;
    if (route.params.type !== 'return') {
      this.getTables();
      this.getUrlQuery(route);
      this.getCountryOptions();
      this.getApplicationStatus();
      this.getStepFailList();
    }
  }

  public getUrlQuery(route: any) {
    const query = route.query;
    if (Object.keys(query).length) {
      const stepKey = query.stepKey as string;
      if (query.failReason) {
        this.stepFailKey = stepKey;
        this.stepStatusKey = 'STEP_FAILURE'
      } else {
        this.stepStatusKey = stepKey;
        this.stepFailKey = '';
      }
      const year = query.year;
      if (year) {
        if (year !== '0') {
          this.setUrlYear(year as string);
        }
      } else {
        const startTime = query.startTime;
        const endTime = query.endTime;
        if (startTime && endTime) {
          this.reqFilterTable.startTime = startTime;
          this.reqFilterTable.endTime = endTime;
        }
      }
    }
  }

  public setUrlYear(year: string) {
    this.reqFilterTable.startTime = `${year}-01-01`;
    this.reqFilterTable.endTime = `${year}-12-31`;
  }

  // 资料提交状态：0新建 1已提交 2需补件 3加申 4在线表单提交
  public getEditText(status: number | null) {
    if (status === 3 || status === 1) {
      return this.$t('stuManagement.addApplyCourse') + '';
    } else {
      return this.$t('stuManagement.edit') + '';
    }
  }

  // 获取失败原因下拉数据
  public async getStepFailList() {
    const { data } = await getStepFailListApi();
    this.stepFailOptions = data.datas;
  }

  // 获取国家下拉数据
  public async getCountryOptions() {
    const { data } = await getAllAreaCountryListApi();
    this.countryOptions = data.datas;
  }
  // 获取学校下拉数据
  public async getInstitutionOptions(fkAreaCountryId: number) {
    const { data } = await getInstitutionListApi(fkAreaCountryId);
    this.institutionOptions = data.datas;
  }

  public submitDrawerForm(submitBtnRef: any) {
    this.courseApplyStatusRef.validate(async (valid: any) => {
      if (valid) {
        submitBtnRef.loading = true;
        const status = this.courseApplyStatus.status;
        const studentId = this.currentStudentId ? this.currentStudentId : null;
        updateStudentStatusApi(status, studentId).then(({ data }) => {
          submitBtnRef.loading = false;
          if (data.code === '1003' && data.success) {
            this.currentStudentId = null;
            this.drawer = false;
            this.getTables();
            this.$message.success(this.$t('message.saveSuccessMsg') as string);
          }
        }).catch(() => {
          submitBtnRef.loading = false;
        });
      }
    })
  }

  public handleStepStatusKey(stepKey: string) {
    if (stepKey !== 'STEP_FAILURE') {
      this.stepFailKey = '';
    }
  }

  public handleSelectCountry(countryId: number) {
    this.reqFilterTable.fkInstitutionId = null;
    if (!countryId) { return; }
    this.getInstitutionOptions(countryId);
  }

  public handleInstitutionChange(institutionId: number) {
    this.reqFilterTable.fkInstitutionId = institutionId;
  }

  public handleCheckStudentInfo(row: any) {
    window.open(
      `${process.env.VUE_APP_OLD_ISSUE_URL}/stu_appl/getOldIssueApplicationForm/?fkStudentId=${row.fkOldIssueStudentInfoId || ''}&bmsAgentId=${this.agentId || ''}`,
      '_blank'
    );
  }

  public handleEditStatus(row: any) {
    this.currentStudentId = row.id;
    this.drawer = true;
  }

  public closeDrawer() {
    this.currentStudentId = null;
    this.courseApplyStatusRef.resetFields();
  }

  public cancelDrawerForm(submitBtnRef: any) {
    this.drawer = false;
    submitBtnRef.loading = false;
  }

  public handleSearch() {
    this.tablePage.currentPage = 0;
    this.getTables();
  }

  public handleReset() {
    this.reqFilterTable = {
      endTime: '',
      fkAreaCountryId: null,
      fkInstitutionId: null,
      fkInstitutionName: '',
      keyWord: '',
      startTime: '',
      stepKey: ''
    };
    this.stepStatusKey = '';
    this.tablePage = {
      showCount: GlobalVar.SHOWCOUNT,
      currentPage: 0,
      totalResult: 0,
      totalIssueItem: 0,
    }
    this.getTables();
  }

  public handleEdit(row: any) {
    const stuid = row.id;
    const agentid = row.fkAgentId;
    this.$router.push({name: 'StudentApplicationForm', params: { stuid }, query: { agid: agentid }});
  }

  public async handleAddApplyCourse(row: any) {
    const stuid = row.id;
    const agentid = row.fkAgentId;
    await updateStudentStatusApi(3, stuid);
    this.$router.push({name: 'StudentApplicationForm', params: { stuid }, query: { agid: agentid }});
  }

  public showIdentificationDialog() {
    if ([2, 4, 5, 6, 3].includes(this.companyId) && this.isBmsLogin) {
      this.dialogVisible = true;
    } else {
      this.$router.push({name: 'StudentRegister'});
    }
  }
  // 批量转学生管理账号
  public showBatchTransferStudent() {
    if (this.selectList.length) {
      this.showTransferDialog=true;
    } else {
      this.$message.info(this.$t('common.plsSelect') as string);
    }
  }
  // 勾选列表
  public handleSelectionChange(selects: any) {
    this.selectList = selects;
  }
  // 返回值用来决定这一行的 CheckBox 是否可以勾选
  public selectable(row: any, idx: number) {
    if (row.isBmsStudent) {
      return false;
    }
    return true;
  }
  public handleRegister() {
    this.$router.push({name: 'StudentRegister'});
  }

  // 导出表格
  public handleExport() {
    const reqData = Object.assign({}, this.reqFilterTable);
    exportStudentExcelApi(reqData).then((res) => {
      const blob = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=utf-8' });
      const navigator: any = window.navigator;
      const nowTime = new Date().toLocaleString();
      const fileName = this.$t('route.studentInfoManagement') + nowTime;
      if (navigator.msSaveOrOpenBlob) { // 兼容IE10
        navigator.msSaveBlob(blob, fileName);
      } else {
        const href = URL.createObjectURL(blob); // 创建新的URL表示指定的blob对象
        const a = document.createElement('a'); // 创建a标签
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = href; // 指定下载链接
        a.download = fileName + '.xls' // 指定下载文件名
        a.click(); // 触发下载
        URL.revokeObjectURL(a.href); // 释放URL对象
      }
    })
  }

  public handlePersonInfo(row: any) {
    const stuid = row.id;
    this.$router.push({name: 'StudentInfo', params: { stuid }});
  }
  // 导出表格
  public handleDownload(row: any) {
    const id = row.id;
    this.downLoadAllFile(id);
  }

  public handleSizeChange(pageSize: number) {
    this.tablePage.showCount = pageSize;
    this.getTables();
  }

  public handleCurrentChange(currentPage: number) {
    this.tablePage.currentPage = currentPage;
    this.getTables();
  }

  public getTableDatas() {
    this.reqFilterTable.stepKey = this.stepFailKey || this.stepStatusKey;
    const reqData = Object.assign({}, this.tablePage, { data: this.reqFilterTable });
    getStuApplyListApi(reqData).then(((res) => {
      const data = res.data;
      this.tablePage = data.page;
      this.getSpanArr(data.datas);
      this.resTableData = data.datas;
    }));
  }

  // data: { row, column, rowIndex, columnIndex }
  public spanMethod(data: any) {
    if (this.mergeProp.includes(data.column.property)) {
      const row = this.mergeData[data.column.property][data.rowIndex]
      const col = 1
      return {
        rowspan: row,
        colspan: col
      }
    }
  }

  public getSpanArr(datas: any) {
    const keyNames = this.mergeProp;
    const recordKeys: string[] = [];
    this.mergeData = [];
    keyNames.forEach((key: string) => {
      recordKeys.push(key);
      datas.forEach((item: any, index: number) => {
        if (index === 0) {
          this.mergeData[key] = this.mergeData[key] || []
          this.mergeData[key].push(1)
          this.mergePos[key] = 0;
        } else {

          const isMerge = this.checkMergeKey(recordKeys, item, datas[index - 1]);
          if (isMerge) {
            this.mergeData[key][this.mergePos[key]] += 1
            this.mergeData[key].push(0)
          } else {
            this.mergeData[key].push(1)
            this.mergePos[key] = index
          }
        }
      });
    })
  }

  public checkMergeKey(recordKeys: string[], item: any, data: any) {
    return recordKeys.every((key: string) => {
      return item[key] === data[key];
    });
  }

  public handleBandOnlineForm() {
    this.$prompt(this.$t('stuManagement.inputOnlineForm') + '', this.$t('common.tip') + '', {
      confirmButtonText: this.$t('common.confirm') + '',
      cancelButtonText: this.$t('common.cancel') + '',
      inputPattern: /\w+/,
      inputErrorMessage: this.$t('stuManagement.inputOnlineFormErr') + ''
    }).then(async ({ value }: any) => {
      try {
        await setBindFormApi(value)
        this.getTables();
        this.$message.success(this.$t('stuManagement.bindSuccess') + '')
      } catch {
        this.handleBandOnlineForm()
      }
    })
  }
}
