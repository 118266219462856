
import CommonIconBtn from './commonIconBtn.vue';
import SubmitBtn from '@/components/Common/SubmitBtn.vue';
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'searchGroup',
  components: {
    SubmitBtn,
    CommonIconBtn
  },
})
export default class SearchGroup extends Vue {
  @Prop({ type: Boolean, default: false}) public searchLoading!: boolean;

  public handleSearch() {
    this.$emit('handle-search');
  }

  public handleReset() {
    this.$emit('handle-reset');
  }
}
