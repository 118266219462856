
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FileAnalysisModule } from '@/store/modules/file_analysis';
import {
  getStudentCreateUserIdListApi,
  convertStuManagerUserApi
} from '@/api/student-management';
import { watch } from 'fs';
import {UserModule} from '@/store/modules/user'
@Component({
  name: 'batchTransferStudentDialog',
})
export default class extends Vue {
  @Prop({ type: Boolean, default: false }) public dialogVisible?: boolean;
  // 列表勾选信息
  @Prop() public selectList!: any;
  // 账号列表
  public accountLists: any=[];
  // 选择的账号
  public transferVale: any='';
  @Watch('dialogVisible')
  public changeShow() {
    if(this.visible) {
      this.transferVale=''
      this.accountLists=[];
      this.getAccounts();
    }
  }
  // 当前账号名称
  get currentUser() {
    return UserModule.name
  }
  // 当前账号id
  get currentUserId() {
    return UserModule.staffId;
  }
  get visible() {
    return this.dialogVisible;
  }
  set visible(newVal) {
    this.$emit('update:dialog-visible', newVal)
  }
  public async getAccounts() {
    const {data}: any=await getStudentCreateUserIdListApi();
    if(data.code==='1003') {
      this.accountLists=data.datas;
    }
  }
  // 提交
  public async success() {
    if(this.transferVale) {
      const sendData: any= {
        fkStudentIds:this.selectList.map((item: any)=> item.id),
        gmtCreateUserId:Number(this.transferVale)
      }
      const {data}: any=await convertStuManagerUserApi(sendData);
      if(data.code==='1003') {
        this.$message.success(this.$t('stuManagement.transferSuccess') as string);
        this.$emit('transferSuccess');
        this.close();
      } else {
        this.$message.error(this.$t('stuManagement.transferFail') as string);
      }
    } else {
      this.$message.info(this.$t('common.plsSelect') as string);
    }
  }
  public close() {
    this.$emit('update:dialog-visible', false)
  }
}
