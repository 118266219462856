
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'commonIconBtn'
})
export default class extends Vue {
  @Prop({type: String}) public name!: string;
  @Prop({type: Boolean, default: false}) public loading!: boolean;
  @Prop({type: String, default: 'primary'}) public type!: string;

  public handleBtnClick() {
    this.$emit('handle-btn-click');
  }
}
