import { render, staticRenderFns } from "./batchTransferStudentDialog.vue?vue&type=template&id=d4524f1e"
import script from "./batchTransferStudentDialog.vue?vue&type=script&lang=ts"
export * from "./batchTransferStudentDialog.vue?vue&type=script&lang=ts"
import style0 from "./batchTransferStudentDialog.vue?vue&type=style&index=0&id=d4524f1e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports