
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { ITablePage } from '@/interface/common';

@Component({
  name: 'tableComp'
})
export default class TableComp extends Vue {
  @Prop() private tablePage!: ITablePage;
  @Prop({ type: String, default: 'width:100%;'}) private tableStyle!: string;
  @Prop() private tableData!: any[];
  @Prop() private columnConfig!: any[];
  @Prop({ type: Boolean, default: true}) private showPage!: boolean;
  @Prop({ type: String, default: ''}) private title!: string;

  @Emit('handle-size-change')
  public handleSizeChange(pageSize: number) {
    return pageSize;
  }

  @Emit('handle-current-change')
  public handleCurrentChange(currentPage: number) {
    return currentPage;
  }
}
