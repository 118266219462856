
import UploadHeader from './UploadHeader.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { enCvParseApi } from '@/api/student-management';
import { FileAnalysisModule } from '@/store/modules/file_analysis';

@Component({
  components: {
    UploadHeader
  }
})
export default class  extends Vue {
  @Prop({ required: true }) private headerInfo!: any;
  @Prop({ required: true }) private contentInfo!: any;
  @Prop({ default: '' }) private mainColor!: string;
  @Prop({ default: true }) private isNotUpload!: boolean;
  @Prop() private type!: string;

  public uploadSuccess() {
    // this.$emit('upload-file', this.type)
  }

  public async fileUpload(file: any) {
    const type = this.type;
    const formData =  new FormData();
    formData.append('file', file);
    formData.append('type', type);
    formData.append(' businessFileType', 'PASSPORT');
    try {
      const { data } = await enCvParseApi(formData);
      const resData = JSON.parse(data.data);
      console.log(resData);
      if (resData.data.error_code) {
        return this.$message.error(resData.data.error_msg);
      }
      if (resData.status === 'success') {
        const passportData = resData.data;
        const guid = resData.fkGuIds[0];
        const gender = ['女/F', '女', 'F'].includes(passportData.Sex) ? 0 : 1;
        if (type === 'issueFileResolverPassportCn') {
          const personInfo = {
            fkGuidAndType: { [guid]: 'PASSPORT' },
            nameZh: passportData.Name,
            birthday: this.getFormatDate(passportData.BirthDate),
            gender, // 1:男 0:女
            lastName: passportData.FamilyName,
            firstName: passportData.FirstName,
            passportNumber: passportData.PassportNo,
            passportIssueDate: this.getFormatDate(passportData.IssueDate),
            passportIssueExpried: this.getFormatDate(passportData.ExpiryDate),
            fkStudentAppCountryIdFrom: 44,
            fkStudentAppCountryIdPassport: 44,
            isHavePassport: true,
            title: gender === 0 ? 2 : 1,
            cityBirth: passportData.BirthPlace,
          }
          this.$emit('set-analysis-status', {type: this.type, personInfo});
          FileAnalysisModule.SET_PERSON_INFO(personInfo);
        } else if (type === 'issueFileResolverPassportNational') {
          const personInfo = {
            fkGuidAndType: { [guid]: 'PASSPORT' },
            nameZh: '',
            birthday: this.getFormatDate(passportData.DateOfBirth),
            gender, // 1:男 0:女
            lastName: passportData.Surname,
            firstName: passportData.GivenName,
            passportNumber: passportData.ID,
            passportIssueDate: "",
            passportIssueExpried: this.getFormatDate(passportData.DateOfExpiration),
            fkStudentAppCountryIdFrom: passportData.CountryId,
            fkStudentAppCountryIdPassport: passportData.CountryId,
            isHavePassport: true,
            title: gender === 0 ? 2 : 1,
            cityBirth: passportData.BirthPlace,
          }
          this.$emit('set-analysis-status', {type: this.type, personInfo});
          FileAnalysisModule.SET_PERSON_INFO(personInfo);
        }
        // else if (type === 'issueFileResolverEduBackground') {
        //   const { educationBackground, name, workingExperience } = resData.data;
        //   const workExp = this.setWorkingExperience(workingExperience);
        //   const eduBack = this.setEducationBackground(educationBackground);
        //   const personInfo = {
        //     lastName: name.firstName.text ? name.firstName.text : undefined,
        //     firstName: name.surname.text ? name.surname.text : undefined,
        //   }
        //   FileAnalysisModule.SET_PERSON_INFO(personInfo);
        //   FileAnalysisModule.SET_WORK_EXP(workExp);
        //   FileAnalysisModule.SET_EDU_BACK(eduBack);
        //   this.$emit('set-analysis-status', {type: this.type, workExp, eduBack });
        // }
      }
    } catch (err) {
      console.log(err);
    }
  }

  public setEducationBackground(educationBackground: any) {
    if (educationBackground) {
      const eduBack = educationBackground.map((eduData: any, index: number) => {
        const fkAreaCityId = eduData.location.cityId;
        const fkAreaStateId = eduData.location.stateId;
        const fkStudentAppCountryId = eduData.location.countryId;
        const key = Date.now() + index;
        return {
          major: eduData.major.text,
          studyInEnglish: eduData.isEnglish ? 2 : 0,
          studyStartDate: eduData.startTime.date,
          studyEndDate: eduData.endTime.date,
          fkStudentAppCountryId: fkStudentAppCountryId === -1 ? '' : fkStudentAppCountryId,
          fkAreaStateId: fkAreaStateId === -1 ? '' : fkAreaStateId,
          fkAreaCityId: fkAreaCityId === -1 ? '' : fkAreaCityId,
          fkInstitutionIdName: eduData.institution.text,
          uniqueKey: key
        }
      });
      return eduBack;
    }
    return [];
  }

  public setWorkingExperience(workingExperience: any): any {
    if (workingExperience.length) {
      const workExp = workingExperience.map((workData: any, index: number) => {
        const companyName = workData.company.text;
        const position = workData.position.text;
        const workStartDate = workData.startTime.date;
        const workEndDate = workData.endTime.date;
        const jobDescription = workData.workScope.text;
        const workMode = workData.workingType;
        const isOnDuty = workData.isContinueJob;
        const fkStudentAppCountryId = workData.location.countryId;
        const fkAreaStateId = workData.location.stateId;
        const fkAreaCityId = workData.location.cityId;
        const key = Date.now() + index;
        return {
          companyName,
          position,
          workStartDate,
          workEndDate,
          jobDescription,
          fkStudentAppCountryId: fkStudentAppCountryId === -1 ? '' : fkStudentAppCountryId,
          fkAreaStateId: fkAreaStateId === -1 ? '' : fkAreaStateId,
          fkAreaCityId: fkAreaCityId === -1 ? '' : fkAreaCityId,
          workMode: workMode === 'part_time' ? 0 : 1, // 0: full time, 1: part time
          isOnDuty,
          uniqueKey: key
        }
      });
      return workExp;
    }
    return [];
  }

  public getFormatDate(date: string) {
    if (date && date.length === 8) {
      const year = date.substring(0, 4); // year only (YYYY) 	YYYY-YYYY 	YYYY-YY
      const month = date.substring(4, 6); // month only (MM)	MM-MM 	MM-MMM 	MMMM-
      const day = date.substring(6, 8); // day only (DD)	DD-DD 	DD-DDD 	DDDD-
      return `${year}-${month}-${day}`;
    }
    return undefined;
  }
}
