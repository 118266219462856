import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';

export interface IFileAnalysisState {
  personInfo: any;
  workExp: any;
  eduBack: any;
}

@Module({ dynamic: true, store, name: 'fileAnalysis' })
class FileAnalysis extends VuexModule implements IFileAnalysisState {
  public personInfo: any = {};
  public workExp: any = {};
  public eduBack: any = {};

  @Mutation
  public SET_PERSON_INFO(personInfo: any) {
    if (Object.keys(personInfo).length) {
      this.personInfo = Object.assign(this.personInfo, personInfo);
    } else {
      this.personInfo = {};
    }
  }

  @Mutation
  public SET_WORK_EXP(workExp: any) {
    this.workExp = workExp;
  }

  @Mutation
  public SET_EDU_BACK(eduBack: any) {
    this.eduBack = eduBack;
  }

}

export const FileAnalysisModule = getModule(FileAnalysis);
