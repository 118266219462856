import { render, staticRenderFns } from "./repeat_student_dialog.vue?vue&type=template&id=3ce9978d"
import script from "./repeat_student_dialog.vue?vue&type=script&lang=ts"
export * from "./repeat_student_dialog.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports