import { Component, Vue } from 'vue-property-decorator';
import { GlobalVar } from '@/utils/global-var';
import { ITablePage } from '@/interface/common';
import { getApplicationStatusApi } from '@/api/student-management';
import {
  downLoadAllFileApi,
} from '@/api/student-management';

@Component
export default class StudentMixin extends Vue {

  public applyStatusOptions: any = [];

  public tablePage: ITablePage = {
    showCount: GlobalVar.SHOWCOUNT,
    currentPage: 0,
    totalResult: 0,
    totalIssueItem: 0,
  }

  // 获取申请状态下拉
  public async getApplicationStatus() {
    const { data } = await getApplicationStatusApi();
    this.applyStatusOptions = data.datas;
  }

  public downLoadAllFile(id: number) {
    downLoadAllFileApi(id).then((res) => {
      if (!res.data) { return; }
      // 文件下载正常情况type为application/octet-stream
      if (res.data.type === 'application/octet-stream') {
        const blob = new Blob([res.data], { type: 'application/zip' });
        const elink = document.createElement('a');
        elink.download = this.$t('stuManagement.studentInformation').toString();
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // 失败的时候type是application/josn, 对失败的结果转换成json，再给出错误提示
        const reader: any = new FileReader();
        reader.onload = () => {
          const data: any = JSON.parse(reader.result);
          this.$message({
            message: data.message,
            type: 'error'
          });
        }
        reader.readAsText(res.data);
      }
    }).catch(() => {
      this.$message({
        message: this.$t('message.noFileMsgOrError').toString(),
        type: 'error'
      });
    })
  }

}
