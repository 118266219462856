import { AppModule } from '@/store/modules/app';
import i18n from '@/lang';
import axios, { ResponseData, checkCookieValidity, loginTimeoutMessageBox } from './request_config';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { Message, Loading } from 'element-ui';
import { UserModule } from '@/store/modules/user';

// 白名单
const whiteList = [
  '/issue/student/exportEasyExcel',
  '/issue/student/exportStudentExcel',
  '/appFile/issueDownloadList',
  '/en_cv_parse/',
  '/sale-center/sale/agent/verifyIdCard'
];

// 全局loading
let globalLoading: any = null;
// 接口数量
let requestCount: number = 0;

// 开启loading
const startLoading = () => {
  globalLoading = Loading.service({
    text: i18n.t('common.loading') as string,
    background: 'rgba(0, 0, 0, 0)',
    target: document.body,
    lock: true,
    customClass: 'loadingClass'
  })
}
// 当前接口数为0时关闭loading
const endLoading = () => {
  if (globalLoading && requestCount <= 0) {
    setTimeout(() => {
      globalLoading.close();
    }, 500);
  }
}
// 添加1个接口数
const addRequestCount = (() => {
  requestCount++;
})
// 减少1个接口数
const delRequestCount = (() => {
  requestCount--;
})

const service: AxiosInstance = axios.create();

service.interceptors.request.use((config: AxiosRequestConfig) => {
  if (UserModule.token) {
    config.headers.at = UserModule.token;
  }
  config.headers.langCode = AppModule.language;
  addRequestCount();
  startLoading();
  return config;
}, (error) => {
  return Promise.reject(error);
});

service.interceptors.response.use((response: AxiosResponse<ResponseData>) => {
  const res: ResponseData = response.data;
  const code = res.code;
  delRequestCount();
  endLoading();
  if (code === '1003') {
    return response;
  } else {
    if (code === '0006') {
      // checkCookieValidity();
      loginTimeoutMessageBox(i18n.t('common.loginTimeout') as string);
    } else {
      const url: string = response.config.url as string;
      if (whiteList.indexOf(url) !== -1) {
        return response;
      } else {
        const message = res.message ? res.message : i18n.t('message.unknownError') as string;
        Message.error({
          message: `错误码：${code}，${message}`,
          dangerouslyUseHTMLString: true,
        });
      }
    }
    return Promise.reject(res);
  }
}, (error: AxiosError) => {
  delRequestCount();
  endLoading()
  const status = error.response?.status;
  if (status === 401) {
    loginTimeoutMessageBox(i18n.t('message.expired') as string)
  } else {
    loginTimeoutMessageBox(i18n.t('message.unknownError') as string)
  }
  return Promise.reject(error);
});

export default service;
