
import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator';
import { IReqFilterTable } from '@/interface/common';
import { remoteFun, searchEcho } from '@/utils/function_utils';
@Component
export default class SelectPageSearch extends Vue {
  // 值
  @Model('change') public modelValue!: any;
  // 数组源
  @Prop({ default: () => [] }) public sourceArr: any;
  // placeholder文字
  @Prop({ default: '' }) public placeholder?: any;
  // 大小
  @Prop({ default: 'mini' }) public size?: string;
  // 是否多选
  @Prop({ default: false }) public isMultiple?: boolean;
  // 多选时是否将选中值按文字的形式展示
  @Prop({ default: false }) public isCollapseTags?: boolean;
  // 是否支持远程
  @Prop({ default: false }) public remote?: boolean;
  // 是否禁用
  @Prop({ default: false }) public disabled?: boolean;
  // 是否禁用下拉选项
  @Prop({ default: () => false }) public getOptionsStatus?: any;
  // 下拉的name和value值
  @Prop({ default: { name: 'name', id: 'id' } }) public selectKeyName?: any;
  // 设置每页多少条
  @Prop({ default: 10 }) public pageSize?: number;
  // 操作
  @Prop({ default: 'add' }) public opera?: string;
  // 大于多少条做分页，默认是100条
  @Prop({ default: 100 }) public maxIsPage!: number;
  // 需要过滤的字段数组
  @Prop({ default: () => []}) public filterArr?: any;

  // 总数据数组
  public optionList: any = [];
  // 远程搜索之后的数组
  public remoteOptions: any = [];
  // 总分页数据
  public allpageOption: any = [];
  // 当前页数据
  public paginationOption: any = [];
  // 是否第一次加载
  public isFirstLoad: boolean = true;
  // 当选择后记住页数
  public isSelectValuePage: number = 1;
  public reqFilterTable: IReqFilterTable<any> = {
    currentPage: 1,
    showCount: 0,
  };

  @Watch('$route')
  public changeRoute() {
    this.isFirstLoad = true;
  }
  // 检测数组源是否有变
  @Watch('sourceArr', { deep: true })
  public changeList() {
    this.setSelectKeyName();
  }
  @Watch('modelValue') // 一次加载 infoLoading防止多次加载
  public changeModelValue() {
    this.dataEchoLogic();
  }

  public mounted() {
    if (this.sourceArr.length) {
      this.setSelectKeyName();
    }
  }
  // 替换下拉的name值和value值
  public setSelectKeyName() {
    if (this.sourceArr && this.sourceArr.length > 0) {
      this.optionList = this.sourceArr.map((item: any) => {
        return {
          name: item[this.selectKeyName.name],
          id: item[this.selectKeyName.id],
          state: item[this.selectKeyName.state],
          fullName: item[this.selectKeyName.fullName],
        };
      });
      this.loadingSourceArr();
    } else {
      this.optionList = [];
      this.remoteOptions = [];
      this.allpageOption = [];
      this.paginationOption = [];
    }
  }
  // 加载原数据
  public loadingSourceArr() {
    if (this.optionList.length > 0) {
      this.remoteOptions = this.optionList;
    } else {
      this.remoteOptions = [];
    }
    this.setPaginationOption();
  }
  // 分页数据 100条数据以上才做分页
  public setPaginationOption() {
    if (this.optionList.length <= 0) {
      return;
    }
    if (this.optionList.length > this.maxIsPage) {
      if (this.remoteOptions.length) {
        let pageSize: number = 0;
        if (this.pageSize) {
          pageSize = this.pageSize;
        }
        this.reqFilterTable.currentPage = 1;
        this.reqFilterTable.showCount = pageSize;
        let arr: any = [];
        const allArr: any = [];
        let index: number = 1;
        for (const key of this.remoteOptions) {
          if (index < pageSize) {
            index++;
            arr.push(key);
          } else {
            arr.push(key);
            allArr.push(arr);
            index = 1;
            arr = [];
          }
        }
        allArr.push(arr);
        this.allpageOption = allArr; // 一共有多少页得数组
        const currentPage: any = this.reqFilterTable.currentPage; // 当前页
        const thisArr: any = allArr[currentPage - 1];
        if (thisArr.length > 0) {
          this.paginationOption = thisArr; // 获取第N页得数组
        } else {
          this.paginationOption = [];
        }
        this.reqFilterTable.totalResult = this.remoteOptions.length; // 总条数
      } else {
        this.paginationOption = [];
      }
    } else {
      if (this.remoteOptions.length > 0) {
        this.paginationOption = this.remoteOptions;
      } else {
        this.paginationOption = [];
      }
    }
    this.dataEchoLogic();
  }
  // 当前页
  public handleCurrentChange(curPage: number) {
    const pageData: any = this.allpageOption[curPage - 1];
    if (pageData && pageData.length > 0) {
      this.paginationOption = pageData;
    } else {
      this.paginationOption = [];
    }
  }
  // 远程搜索方法
  public remoteMethod(query: any) {
    if (this.remote) {
      if (query) {
        if (this.filterArr && this.filterArr.length) {
          this.remoteOptions = remoteFun(query, this.optionList, this.filterArr);
        } else {
          this.remoteOptions = remoteFun(query, this.optionList, 'name');
        }
        this.setPaginationOption();
      } else {
        this.loadingSourceArr();
      }
    }
  }
  // 数据回显前的逻辑
  public dataEchoLogic() {
    if (this.modelValue && this.sourceArr.length > 0) {
      if (this.isFirstLoad) {
        this.isFirstLoad = false;
        this.setSearchEcho();
      }
    }
  }
  // 回显数据
  public setSearchEcho() {
    if (this.isMultiple) {
      // 多选
      this.remoteOptions = searchEcho(this.optionList, this.modelValue, 'arr');
    } else {
      this.remoteOptions = searchEcho(this.optionList, this.modelValue);
    }
    this.setPaginationOption();
  }
  // 当下拉关闭时页码和数组复位
  public switchChange(bool: boolean) {
    this.isFirstLoad = false;
    if (bool && this.optionList.length > this.maxIsPage) {
      if (
        (this.modelValue && this.modelValue.length > 0) ||
        (this.modelValue && this.isMultiple === false)
      ) {
        this.reqFilterTable.currentPage = this.isSelectValuePage;
        this.handleCurrentChange(this.isSelectValuePage);
      } else {
        this.loadingSourceArr();
      }
    } else if (bool && this.optionList.length <= this.maxIsPage) {
      this.loadingSourceArr();
    }
  }
  // 输出 当有选中时 下次显示回对应的页码
  public emitValue(event: any) {
    if (event) {
      this.isSelectValuePage = this.reqFilterTable.currentPage as number;
    } else {
      this.loadingSourceArr();
    }
    this.$emit('changeVal', event);
  }
  // 重置
  public clear() {
    this.isFirstLoad = true;
    this.optionList = [];
    this.remoteOptions = [];
    this.allpageOption = [];
    this.paginationOption = [];
  }
}
