
import StudentFormInfo from './components/StudentFormInfo.vue'
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'studentRegister',
  components: {
    StudentFormInfo
  }
})
export default class extends Vue {}
