
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { updateStudentStatusApi } from '@/api/student-management';
@Component({
  name: 'repeatStudentDialog',
})
export default class extends Vue {
  @Prop({default: () => []}) repeatStudentList:any;

  public isShow:boolean=false;
  
  public goDetail(item:any){
    if(item.status === 1 || item.status === 3) {
      updateStudentStatusApi(3,item.id).then((res:any)=>{
        this.$router.push({name: 'StudentApplicationForm',
        params: { stuid:item.id }, query: { agid: item.fkAgentId }});
        this.isShow=false;
      })
    }else{
      this.$router.push({name: 'StudentApplicationForm',
      params: { stuid:item.id }, query: { agid: item.fkAgentId }});
      this.isShow=false;
    }
  }
}
