
import StudentFormInfo from './components/StudentFormInfo.vue'
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'studentInfo',
  components: {
    StudentFormInfo
  }
})
export default class extends Vue {

  public handleReturnStuList() {
    this.$router.push({
      name: 'studentInfoManagement',
      params: {
        type: 'return'
      }
    })
  }
}
